@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: Bagero;
  src: url(../../fonts/Bagero-ALDJp.otf);
}
@layer utilities {

    .clip-path-home {
        clip-path: polygon(100% 0, 93% 49%, 100% 100%, 0% 100%, 0 49%, 0% 0%);
    }
 
 }
 html {
    scroll-behavior: smooth;
  }

  //SignIm
  .contain {
    background-image: url("../images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .sign {
    height: 120px;
    width: 250px;
    background: linear-gradient(180deg, #E93B77 0%, #DA1F63 100%);
    position: absolute;
    top: 200px;
    z-index: 10;
    box-shadow: 0px 2px 6px rgba(220, 34, 101, 0.36);
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: all 0.2s;
  }
  .sign p {
    margin: 10px 0px;
  }
  .icons a {
    padding: 0px 5px;
  }
  .sign img:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .sign img:active {
    transform: translateY(1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .box {
    height: 350px;
    width: 280px;
    background-color: white;
    position: absolute;
    top: 230px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }
  .form {
    margin-top: 100px;
    color: #7B809A;
  }
  .form input {
    width: 250px;
    height: 20px;
    padding: 15px;
    margin-top: 10px;
    margin-left: 8px;
    border: 1px solid #C7CCD0;
    border-radius: 8px;
  }
  .form input:hover {
    border: 2px solid #DA1F63;
  }
  .lab {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  .lab p {
    margin: 0px 5px;
    padding: 0px;
  }
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 15px;
    background: grey;
    display: block;
    border-radius: 30px;
    position: relative;
  }
  label:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
    height: 15px;
    background: rgb(252, 245, 245);
    border-radius: 20px;
    transition: 0.3s;
  }
  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  .btn {
    width: 270px;
    height: 30px;
    background: linear-gradient(180deg, #E93B77 0%, #DA1F63 100%);
    border-radius: 8px;
    border: 1px solid #DA1F63;
    margin-left: 5px;
    margin-top: 20px;
    transition: all 0.2s;
    text-decoration: none;
    color: white;
  }
  .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .signup {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .signup a {
    text-decoration: none;
    color: #E93B77;
  }